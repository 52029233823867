import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../Components/Navbar/Navbar';
import AboutNoButton from '../Components/About/AboutNoButton';
import Footer from '../Components/Footer/Footer'
import PageHeader from '../Components/PageHeader/PageHeader'
import Newsletter from '../Components/Newsletter/Newsletter'

//Styles
import '../Components/Navbar/Navbar.scss'

// Images
import aboutImage from '../Images/about1.jpg'
import woundedImage from '../Images/wounded2.jpeg'

function AboutPage() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Navbar homeNav='navbar navbar-expand-md navbar-dark bg-custom fixed-top navbarScroll' />
      <PageHeader pageName='About Us' />
      <AboutNoButton
        homeImage={aboutImage}
      />
      <div id='about' className='changePadding'>
        <div className='row v-center'>
          <div className='col-lg text-center order-2 order-md-2'>
            <img src={woundedImage} alt="image" className='aboutImage3' />
          </div>
          <div className='col-lg order-1 order-md-1'>
            <div className='aboutText'>
              <h1>Wounded Silent Warriors</h1>
              <div className='textIndent'>
                <p>FAMIL’s Wounded Heroes Program is focused on assisting Afghan veterans who were wounded in action (WIA) while serving alongside US forces in Afghanistan – including individuals living with amputations, paralysis, and severe blast injuries. Our program seeks to provide customized solutions to address the immediate and unmet needs of this population – with an emphasis on medical needs affecting their physical, emotional, and mental wellness. In addition to addressing their medical needs, our program aims to connect these individuals with jobs and job-related training, English learning tools, and immigration assistance. Our vision is to help wounded Afghan veterans get back on their feet as they work to achieve self-sufficiency, restore their dignity, and pursue opportunities to rebuild what they sacrificed on the battlefield.</p>
              </div>
            </div>
          </div>
        </div>
      </div >
      <Newsletter />
      <Footer />
    </div>
  );
}

export default AboutPage;
