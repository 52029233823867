import React from 'react'
import './PageHeader.scss'

const PageHeader = ({pageName}) => {
  return (
    <div id='pageheader'>
        <h1>{pageName}</h1>
    </div>
  )
}

export default PageHeader