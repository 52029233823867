import React from 'react'
import Navbar from '../Components/Navbar/Navbar';
import PageHeader from '../Components/PageHeader/PageHeader'
import GallerySection from '../Components/GallerySection/GallerySection'
import Footer from '../Components/Footer/Footer'
import Newsletter from '../Components/Newsletter/Newsletter'

function GalleryPage() {
    return (
        <div>
            <Navbar homeNav='navbar navbar-expand-md navbar-dark bg-custom fixed-top navbarScroll' />
            <PageHeader pageName='OUR GALLERY' />
            <GallerySection />
            <Newsletter />
            <Footer />
        </div>
    )
}

export default GalleryPage