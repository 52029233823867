import React from 'react'
import './Questions.scss'

function Questions() {
  return (
    <div id='questions'>
      <h1>Do you have questions about FAMIL?</h1>
      <a href="/faq/"><div className="btn learnButton text-nowrap">FAQ</div></a>
    </div>
  )
}

export default Questions