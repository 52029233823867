import React from 'react'
import './Testimonials.scss';
import ReactPlayer from 'react-player'

// import video from '../../Images/familvideo.mp4'

function Testimonials() {
    return (
        <div>
            <div id='testimonials'>
                <div className='quoteContainer'>
                    <h1>Testimonials</h1>
                    <div className='row'>
                        <div className='col-lg testimonialCard'>
                            <h2>“They served alongside U.S. troops for two decades. They risked their lives to protect American lives.”</h2>
                            <p>- LTG John F. Mulholland (USA, Ret)</p>
                        </div>
                        <div className='col-lg mvSpacing testimonialCard'>
                            <h2>“Our number one goal is to educate our children. They will go to school here. We are ready to help them.”</h2>
                            <p>- A couple describing plans for their children's education</p>
                        </div>
                        <div className='col-lg testimonialCard'>
                            <h2>"We lost everything when we left. But we will replace what we lost with books, education, and a fresh start for our family."</h2>
                            <p>- An Afghan family expressing hope for their future</p>
                        </div>
                    </div>
                </div>
            </div>

            <div id='video'>
                <ReactPlayer
                    className='react-player'
                    url='https://player.vimeo.com/video/722448933?h=13db40b618'
                    width='100%'
                    controls='true'
                />
            </div>
        </div>
    )
}

export default Testimonials