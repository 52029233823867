import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../Components/Navbar/Navbar';
import Footer from '../Components/Footer/Footer'
import PageHeader from '../Components/PageHeader/PageHeader'
import Faq from '../Components/Faq/Faq'
import Newsletter from '../Components/Newsletter/Newsletter'

//Styles
import '../Components/Navbar/Navbar.scss'

function FaqPage() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Navbar homeNav='navbar navbar-expand-md navbar-dark bg-custom fixed-top navbarScroll' />
      <PageHeader pageName='FREQUENTLY ASKED QUESTIONS' />
      <Faq />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default FaqPage;
