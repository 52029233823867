import React from 'react'
import './About.scss';

const About = ({ p1, p2, p3, p4, p5, aboutHeader, homeImage, link }) => {
    return (
        <div id='about'>
            <div className='row v-center'>
                <div className='col-md text-center order-2 order-md-1'>
                    <img src={homeImage} alt="Image" className='aboutImage2' />
                </div>
                <div className='col-md order-1 order-md-2'>
                    <div className='aboutText'>
                        <h1>{aboutHeader}</h1>
                        <div className='textIndent'>
                            <p>{p1}</p>
                            <p>{p2}</p>
                            <p>{p3}</p>
                            <p>{p4}</p>
                            <p style={{ fontWeight: "bold" }}>{p5}</p>
                        </div>
                        <a href={link}><div className="btn learnButton text-nowrap">Learn More</div></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About