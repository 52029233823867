import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../Components/Navbar/Navbar';
import AboutNoButton from '../Components/About/AboutNoButton';
import Footer from '../Components/Footer/Footer'
import PageHeader from '../Components/PageHeader/PageHeader'
import Team from '../Components/Team/Team'
import Newsletter from '../Components/Newsletter/Newsletter'

//Styles
import '../Components/Navbar/Navbar.scss'

// Images
import teamImage from '../Images/teamimage.png'
import founderImage from '../Images/founder.jpg'

function TeamPage() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Navbar homeNav='navbar navbar-expand-md navbar-dark bg-custom fixed-top navbarScroll' />
      <PageHeader pageName='Our Team' />
      {/* <AboutNoButton 
          homeImage={teamImage}
          aboutHeader="Meet Our Team"
          p1="FAMIL is driven by a team of individuals with a diversity of experiences, a passion for results, and a commitment to service. "
          p2="We are former US intelligence professionals, US military veterans, and Afghan-Americans who served with Afghan partners in Afghanistan."
          p3="We are first-and-second generation Americans with multicultural backgrounds, and Afghans on the path towards US citizenship."
          p4="We have a deep understanding of the needs of the Afghan community and a proficiency in implementing solutions to meet those needs."
          p5='We are diverse, we are committed, and we care.'
        /> */}
      <div id='about' className='changePadding'>
        <div className='row v-center'>
          <div className='col-lg text-center order-2 order-md-1'>
            <img src={founderImage} alt="Image" className='aboutImage3' />
          </div>
          <div className='col-lg order-1 order-md-2'>
            <div className='aboutText'>
              <h1>A message from our founder</h1>
              <div className='textIndent'>
                <p>“For it is in giving that we receive”</p>
                <p>I am one of several thousand Americans who had the privilege of serving among America’s finest heroes in Afghanistan – including our Afghan partners. For two decades, our Afghan partners served two countries and risked their lives to protect American lives. Without a doubt, we owe many of our successes to these courageous Afghans – including members of the Afghan special forces – without whom some of us would not be here today.</p>
                <p>In the wake of the US withdrawal from Afghanistan, I felt compelled to establish FAMIL – which means “family” in Afghanistan – to provide our Afghan partners a lifeline in the country they served for so long. These brave men and women were forced to evacuate from their homes. Now in the United States, FAMIL is dedicated to helping them rebuild the lives they sacrificed in Afghanistan.</p>
                <p>Our Afghan partners were our family in their homeland. Now, they are part of our homeland. Join me and FAMIL in honoring our commitment to our Afghan partners by setting them up for success in America.</p>
                <p>– Geeta Bakshi, Founder, FAMIL</p>
              </div>
            </div>
          </div>
        </div>
      </div >
      <Team />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default TeamPage;
