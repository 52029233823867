import React from 'react'
import './Newsletter.scss'
import { useForm, ValidationError } from '@formspree/react';

function MailForm() {
  const [state, handleSubmit] = useForm("xwkyzebb");
  if (state.succeeded) {
    return <form onSubmit={handleSubmit}>
      <input
        id="email"
        type="email"
        name="email"
        placeholder='Email'
      />
      <ValidationError
        prefix="Email"
        field="email"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting} className="btn learnButton text-nowrap">
        Subscribed
      </button>
    </form>;
  }
  return (
    <form onSubmit={handleSubmit}>
      <input
        id="email"
        type="email"
        name="email"
        placeholder='Email'
      />
      <ValidationError
        prefix="Email"
        field="email"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting} className="btn learnButton text-nowrap">
        Subscribe
      </button>
    </form>
  );
}
function Form() {
  return (
    <MailForm />
  );
}

function Newsletter() {
  return (
    <div id='newsletter'>
      <div className='newsletterContainer'>
        <h1>Subscribe to our Newsletter</h1>
        <div className='inputContainer'>
          <Form />
        </div>
      </div>
    </div>
  )
}

export default Newsletter