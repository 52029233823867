import React from 'react'
import './About.scss';

const AboutNoButton = ({ p1, p2, p3, p4, p5, aboutHeader, homeImage, link }) => {
    return (
        <div id='about' className='changePadding'>
            <div className='row v-center'>
                <div className='col-lg text-center order-2 order-md-1'>
                    <img src={homeImage} alt="Image" className='aboutImage3' />
                </div>
                <div className='col-lg order-1 order-md-2'>
                    <div className='aboutText'>
                        <h1>FAMIL is organized around three key focus areas: Employment, Education, and Integration.</h1>
                        <div className='textIndent'>
                            <p>FAMIL believes that Afghan newcomers will soon become vital members of their communities as they settle into their housing, have transportation needs met, secure suitable employment, enroll in schooling, and have a trusted advisor for the early stages of their American journey.</p>
                            <p>FAMIL is built around the philosophy of teaching others how to do something, rather than simply doing it for them. We aim to help our clients find lasting solutions to short and long-term issues within our three key program areas:</p>
                            <p><b>Employment. </b>Our Afghan partners have unique, marketable, and desirable skills – including security, technology, machinery, communication, and medical capabilities. They are hard-working, loyal, and eager to become valued members of a team. FAMIL helps them identify suitable employment opportunities, as well as assists them in learning all phases of the employment process, to include submitting job applications, preparing up-to-date resumes, and preparing for job interviews.</p>
                            <p><b>Education. </b>Many of our Afghan partners – including children and adults of all ages – desire educational opportunities, including English language, elementary to high school education, college and university studies, driver’s education, and trade certifications. FAMIL works to identify appropriate schooling and assists them with enrollment, financial aid, and digital learning resources.</p>
                            <p><b>Integration. </b>FAMIL mentors our Afghan partners on how to navigate their new communities and teaches them how to overcome barriers to resettlement. We assist with a full range of services aimed at helping our Afghan partners integrate into America, such as: enrolling in benefits; navigating medical processes; banking and budgeting; driver’s licensing and vehicle services; managing housing, utilities, and bills; navigating immigration processes; transportation; communication; and interpretation. In other words, FAMIL shows our Afghan partners the path to success in America.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AboutNoButton