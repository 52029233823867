import React from 'react'
import './Donate.scss'
import { useForm, ValidationError } from '@formspree/react';

// Images
import aboutImage from '../../Images/donate.png'

function ContactForm() {
    const [state, handleSubmit] = useForm("xknyewbj");
    if (state.succeeded) {
        return <p>Thank you for submitting!</p>;
    }
    return (
        <form onSubmit={handleSubmit}>
            <input
                id="name"
                type="name"
                name="name"
                placeholder='Name'
            />
            <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
            />
            <input
                id="email"
                type="email"
                name="email"
                placeholder='Email'
            />
            <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
            />
            <button type="submit" disabled={state.submitting} className="btn donateButton text-nowrap">
                Submit
            </button>
        </form>
    );
}
function Form() {
    return (
        <ContactForm />
    );
}

function Donate() {
    return (
        <div id='donate'>
            <div className='row'>
                <div className='col-md text-center order-2 order-md-1'>
                    <img src={aboutImage} alt="Image" className='aboutImage2' />
                    {/* <div className='founderMessage'>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac mauris nisi, velit tempus accumsan. Adipiscing imperdiet bibendum atim duis amet sit et facilisis.</p>
                        <p style={{ fontWeight: "bold" }}>—Geeta Bakshi, Founder, FAMIL</p>
    </div> */}
                </div>
                <div className='col-md order-1 order-md-2'>
                    <div className='donateContainer'>
                        <div>
                            <h2>01/</h2>
                        </div>
                        <div>
                            <h1>Donate via Paypal, Debit or Credit Card</h1>
                            <p>Make a one time or recurring donations.</p>
                            <form action="https://www.paypal.com/donate" method="post" target="_top">
                                <input type="hidden" name="hosted_button_id" value="V62PUVN7HT2DU" />
                                <input className="btn donateButton text-nowrap" type="submit" value="Donate" name="Donate" />
                            </form>
                        </div>
                    </div>
                    <div className='donateContainer'>
                        <div>
                            <h2>02/</h2>
                        </div>
                        <div>
                            <h1>Donate via check</h1>
                            <p>Checks can be sent by mail to:</p>
                            <p>FAMIL <br />
                                PO Box 9263 <br />
                                McLean, VA 22102</p>
                        </div>
                    </div>
                    <div className='donateContainer rmborder'>
                        <div>
                            <h2>03/</h2>
                        </div>
                        <div>
                            <h1>Donate via ACH</h1>
                            <p>If you want to send money through ACH/Wire, <br /> please fill out this form</p>
                            <Form />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Donate