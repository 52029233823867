import React from 'react'
import './Landing.scss'

function Landing() {
  return (
    <div id='landing'>
      <div className='heroBackground'>
        <div className='heroContainer'>
          <div className='landingBox'>
            <h1>Helping those who helped us</h1>
            <div className='pContainer'><p>Join us in our mission to support the heroic Afghans who served our beloved nation.</p></div>
            <a href="/donate/"><div className="btn donateButton text-nowrap">Support Us</div></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing