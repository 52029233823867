import React from 'react'
import './Team.scss'
import Popup from 'reactjs-popup';

function Team() {
    return (
        <div id='team'>
            <div id='directors'>
                <div className='teamContainer'>
                    <div className='teamWidth'>
                        <h1>Board of Directors</h1>
                        <div className='line' />
                        <div className='row row-cols-2 row-cols-md-3 g-5'>
                            <Popup trigger={
                                <div className='col'>
                                    <h3 className='textHover'>Geeta Bakshi - Founder</h3>
                                </div>
                            }
                                modal
                                contentStyle={{
                                    width: '90%',
                                    maxWidth: '1000px',
                                }}
                                overlayStyle={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    zIndex: '1000',
                                }}
                            >
                                <div className='popupContainer'>
                                    <h2>Geeta Bakshi</h2>
                                    <p>Geeta Bakshi is a former Central Intelligence Agency (CIA) counterterrorism operations officer and
                                        second-generation American of South Asian descent. A native of Northern Virginia, she graduated from
                                        George Mason University with a major in Criminal Justice and a minor in American Government. Inspired
                                        to serve the United States after the tragic events of 9/11, Ms. Bakshi joined the CIA’s Directorate of
                                        Operations (DO), where she served a 14-year career focused on counterterrorism issues in the
                                        Afghanistan-Pakistan region and other traditional and non-traditional regions. Having spent much of
                                        that time working on the ground in Afghanistan, she lived by the notion of “service before self”,
                                        recognizing she and her colleagues had an enormous responsibility to protect innocent lives from
                                        America’s terrorist adversaries. Among other awards, Ms. Bakshi received the Director of CIA Certificate
                                        of Distinction for her contributions in Afghanistan. Today, she is the Founder and Chief Executive Officer
                                        of FAMIL, a nonprofit organization founded in August 2021 and dedicated to helping Afghan veterans
                                        who served on behalf of the US Government.</p>
                                </div>
                            </Popup>
                            <Popup trigger={
                                <div className='col'>
                                    <h3 className='textHover'>Melissa Allen</h3>
                                </div>
                            }
                                modal
                                contentStyle={{
                                    width: '90%',
                                    maxWidth: '1000px',
                                }}
                                overlayStyle={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    zIndex: '1000',
                                }}
                            >
                                <div className='popupContainer'>
                                    <h2>Melissa Allen</h2>
                                    <p>Melissa Allen is the Chief Operating Officer of the Third Option Foundation, a non-profit organization
                                        dedicated to providing comprehensive assistance to quietly help those who quietly serve. Prior to
                                        joining TOF, she spent over 15 years in the private sector specializing in business development and
                                        program management for Department of Defense and Intelligence Community customers – including
                                        the CIA, NSA, and DIA – focusing on the procurement of counterterrorism, cyber, and counter-
                                        proliferation program opportunities. Ms. Allen also worked for a US Senator on foreign policy and
                                        intelligence legislation, following a two-year tour of service in Central Africa with the Peace Corps. She
                                        holds and MS in International Relations and Security Studies from Georgetown University and a BA in
                                        Political Science and International Relations from the University of San Diego. In developing programs
                                        that support the CIA Special Operations Community, Ms. Allen draws on her lived experience as the
                                        spouse of a former USMC Infantry Officer and Paramilitary Officer. With a passion for serving those who
                                        quietly served, she joined FAMIL’s Board of Directors in February 2022.</p>
                                </div>
                            </Popup>
                            <Popup trigger={
                                <div className='col'>
                                    <h3 className='textHover'>Michael Barry</h3>
                                </div>
                            }
                                modal
                                contentStyle={{
                                    width: '90%',
                                    maxWidth: '1000px',
                                }}
                                overlayStyle={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    zIndex: '1000',
                                }}
                            >
                                <div className='popupContainer'>
                                    <h2>Michael Barry</h2>
                                    <p>Michael Barry is a 26-year veteran of the Central Intelligence Agency’s (CIA’s) Directorate of Operations
                                        (DO), and a member of CIA’s Senior Intelligence Service (SIS). He served as an operations officer and
                                        multi-tour Chief of Station (COS) with nine overseas tours – five in active war zones, including
                                        Afghanistan, Pakistan, and Iraq. Additionally, as a senior CIA officer, Mr. Barry served as a Deputy
                                        Assistant Director in the Counterintelligence Division of the FBI, as well as a Special Assistant to the
                                        President of the United States for Intelligence Programs at the U.S. National Security Council. Over the
                                        course of his career, he conducted human intelligence operations in denied areas, war zones, and other
                                        non-traditional and traditional overseas environments.  Prior to joining the CIA, Mr. Barry served as an
                                        officer in the United States Air Force for 11 years and served for three years in the Criminal Division of
                                        the U.S. Justice Department. Among other awards, he is the recipient of the Presidential Rank Award,
                                        the DNI Superior Service Medal, CIA’s Director’s Award, and CIA’s Distinguished Career Intelligence
                                        Medal. Inspired to encourage the Afghan partners who sacrificed so much for the United States, Mr.
                                        Barry joined FAMIL’s Board of Directors in December 2021.</p>
                                </div>
                            </Popup>
                            <Popup trigger={
                                <div className='col'>
                                    <h3 className='textHover'>Dr. Newton Howard</h3>
                                </div>
                            }
                                modal
                                contentStyle={{
                                    width: '90%',
                                    maxWidth: '1000px',
                                }}
                                overlayStyle={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    zIndex: '1000',
                                }}
                            >
                                <div className='popupContainer'>
                                    <h2>Dr. Newton Howard</h2>
                                    <p>Dr. Newton Howard is a brain and cognitive scientist who serves as a professor of Brain Sciences at
                                        Georgetown University. He also serves as a professor of Computational Neurology and Functional
                                        Neurosurgery at the University of Oxford. While Dr. Howard’s career formed in military scientific
                                        research, in 2002, he founded the Center for Advanced Defense Studies (CADS) – a leading Washington,
                                        DC, national security group. Currently, he serves as the Director of the Board for CADS. Dr. Howard is
                                        also a national security advisor with over 30 years of experience developing emergent technologies and
                                        products in the fields of artificial intelligence (AI) and neuroscience. He is an active member of several

                                        research laboratories worldwide and seeks to understand the language of the brain for the purpose of
                                        providing advanced methods of detection and treatment for many neurological disorders. Dr. Howard
                                        joined FAMIL’s Board of Directors in October 2022 with the purpose of giving back to those Afghan
                                        veterans who served and sacrificed on behalf of the United States.</p>
                                </div>
                            </Popup>
                            <Popup trigger={
                                <div className='col'>
                                    <h3 className='textHover'>Robert Kee</h3>
                                </div>
                            }
                                modal
                                contentStyle={{
                                    width: '90%',
                                    maxWidth: '1000px',
                                }}
                                overlayStyle={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    zIndex: '1000',
                                }}
                            >
                                <div className='popupContainer'>
                                    <h2>Robert Kee</h2>
                                    <p>Robert Kee is a Managing Director, Head of Special Operations, and Chief of Compliance at Raven &amp;
                                        Company, a private equity firm. Prior to joining Raven &amp; Company, he served for 31 years in the federal
                                        government, where he rose to the highest levels of the United States Intelligence Community. Mr. Kee
                                        held 10 different operational field assignments, including three tours in Afghanistan and other active
                                        war zones in the Middle East and South Asia. During his intelligence career, he spent several years in
                                        Washington, DC, interfacing with senior-level appointees of the White House, Congress, and Inter-
                                        Agency. Mr. Kee joined FAMIL’s Board of Directors in October 2022 to help fulfill its mission of
                                        supporting Afghan partners in their American journey.</p>
                                </div>
                            </Popup>
                            <Popup trigger={
                                <div className='col'>
                                    <h3 className='textHover'>Mike Raiole</h3>
                                </div>
                            }
                                modal
                                contentStyle={{
                                    width: '90%',
                                    maxWidth: '1000px',
                                }}
                                overlayStyle={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    zIndex: '1000',
                                }}
                            >
                                <div className='popupContainer'>
                                    <h2>Mike Raiole</h2>
                                    <p>Mike Raiole is a retired Central Intelligence Agency (CIA) executive who began his CIA career as a
                                        paramilitary and operations officer prior to being appointed to the CIA’s Senior Intelligence Service (SIS)
                                        in 2003. He is also a former Director of CIA’s covert and paramilitary infrastructure, Chief of Station
                                        (COS) of several CIA field stations, and former senior operational leader in CIA’s Counterterrorism
                                        Center. Mr. Raiole received numerous awards for his service, including the Presidential Rank Award and
                                        the CIA Intelligence Star for valor. Before joining the CIA, he served several years as a Force
                                        Reconnaissance Marine and graduated in 1978 from the American Cooperative School in Monrovia,
                                        Liberia. After his retirement from the CIA, Mr. Raiole was the Chief Executive Officer (CEO) of Oritain
                                        Government Services. A resident of Northern Virginia, he joined FAMIL’s Board of Directors in
                                        September 2021. Mr. Raiole is passionate about FAMIL’s mission of helping Afghan partners and their
                                        families resettle successfully in the United States.</p>
                                </div>
                            </Popup>
                            <Popup trigger={
                                <div className='col'>
                                    <h3 className='textHover'>Karen Schaefer</h3>
                                </div>
                            }
                                modal
                                contentStyle={{
                                    width: '90%',
                                    maxWidth: '1000px',
                                }}
                                overlayStyle={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    zIndex: '1000',
                                }}
                            >
                                <div className='popupContainer'>
                                    <h2>Karen Schaefer</h2>
                                    <p>Karen Schaefer held numerous leadership positions within the Central Intelligence Agency (CIA) during
                                        her 26-year career, with overseas assignments in Latin America, Europe, Afghanistan and Iraq (where she served as Chief of Base). Her headquarters positions included Chief of Operations, Directorate of
                                        Science and Technology; Deputy Associate Director of Military Affairs; Deputy Chief of Operations, Near
                                        East Division; Executive Assistant to Deputy Director CIA; and Group Chief, Iran Operations Division. Ms.
                                        Schaefer also served as Director of Intelligence Programs at the National Security Council (NSC) and in
                                        the leadership team of the FBI as the Director’s Senior Intelligence Representative. She holds a BA in
                                        Spanish and Foreign Affairs from the University of Virginia, and has received numerous awards
                                        throughout her career, including the Presidential Rank Award, CIA’s Distinguished Career Intelligence
                                        Medal, CIA’s Director’s Medal for Extraordinary Fidelity and Essential Service, the United States Special
                                        Operations Command Outstanding Civilian Service Medal, and two Director of National Intelligence
                                        Exceptional Achievement Medals. With a passion for helping others and a bias for action, Ms. Schaefer
                                        joined FAMIL’s Board of Directors in January 2022. She also serves on the Advisory Board of the Third
                                        Option Foundation.</p>
                                </div>
                            </Popup>
                        </div>
                    </div>
                </div>
            </div>
            <div id='advisors'>
                <div className='teamContainer'>
                    <div className='teamWidth'>
                        <h1>Board of Advisors</h1>
                        <div className='line' />
                        <div className='row row-cols-2 row-cols-md-3 g-5'>
                            <div className='col'>
                                <h3>General Haibatullah Alizai</h3>
                            </div>
                            <div className='col'>
                                <h3>Vik Bakshi</h3>
                            </div>
                            <div className='col'>
                                <h3>John Brennan</h3>
                            </div>
                            <div className='col'>
                                <h3>Brian Carbaugh</h3>
                            </div>
                            <div className='col'>
                                <h3>Robert Eatinger</h3>
                            </div>
                            <div className='col'>
                                <h3>Deron (DJ) Haley</h3>
                            </div>
                            <div className='col'>
                                <h3>Andy Hartsog</h3>
                            </div>
                            <div className='col'>
                                <h3>LTG John Mulholland</h3>
                            </div>
                            <div className='col'>
                                <h3>Michael “Mick” Patrick Mulroy</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='staff'>
                <div className='teamContainer'>
                    <div className='teamWidth'>
                        <h1>Staff</h1>
                        <div className='line' />
                        <div className='row row-cols-2 row-cols-md-3 g-5'>
                            <div className='col'>
                                <h3>Nasir Ahmad Andar</h3>
                            </div>
                            <div className='col'>
                                <h3>Puneet Bhatia</h3>
                            </div>
                            <div className='col'>
                                <h3>Adrian Kehlmann</h3>
                            </div>
                            <div className='col'>
                                <h3>Beatrice Lustig</h3>
                            </div>
                            <div className='col'>
                                <h3>Ada Suleyman</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team