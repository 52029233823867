import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/Home'
import AboutPage from './Pages/AboutPage'
import DonatePage from './Pages/DonatePage'
import TeamPage from './Pages/TeamPage'
import FaqPage from './Pages/FaqPage'
import GalleryPage from './Pages/GalleryPage'

import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="team" element={<TeamPage />} />
        <Route path="faq" element={<FaqPage />} />
        <Route path="donate" element={<DonatePage />} />
        <Route path="gallery" element={<GalleryPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
