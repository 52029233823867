import React from 'react'
import './GallerySection.scss'

import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

// Images
import gallery1 from '../../Images/gallery1.png'
import gallery2 from '../../Images/gallery2.png'
import gallery3 from '../../Images/gallery3.png'
import gallery4 from '../../Images/gallery4.png'
import gallery5 from '../../Images/gallery5.png'
import gallery6 from '../../Images/gallery6.png'
import gallery7 from '../../Images/gallery7.png'
import gallery8 from '../../Images/gallery8.png'

function GallerySection() {
    return (
        <div id='gallery'>
            <Gallery>
                <Item
                    className='item'
                    original={gallery1}
                    thumbnail={gallery1}
                    width="1072"
                    height="703"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={gallery1} />
                    )}
                </Item>
                <Item
                    original={gallery2}
                    thumbnail={gallery2}
                    width="1024"
                    height="768"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={gallery2} />
                    )}
                </Item>
                <Item
                    original={gallery3}
                    thumbnail={gallery3}
                    width="941"
                    height="542"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={gallery3} />
                    )}
                </Item>
                <Item
                    original={gallery4}
                    thumbnail={gallery4}
                    width="814"
                    height="390"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={gallery4} />
                    )}
                </Item>
                <Item
                    original={gallery5}
                    thumbnail={gallery5}
                    width="948"
                    height="709"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={gallery5} />
                    )}
                </Item>
                <Item
                    original={gallery6}
                    thumbnail={gallery6}
                    width="950"
                    height="709"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={gallery6} />
                    )}
                </Item>
                <Item
                    original={gallery7}
                    thumbnail={gallery7}
                    width="795"
                    height="712"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={gallery7} />
                    )}
                </Item>
                <Item
                    original={gallery8}
                    thumbnail={gallery8}
                    width="530"
                    height="710"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={gallery8} />
                    )}
                </Item>
                {/* <Item
                cropped
                    original=""
                    thumbnail=""
                    width="1024"
                    height="768"
                >
                    {({ ref, open }) => (
                        <img ref={ref} onClick={open} src="" />
                    )}
                    </Item> */}
            </Gallery>
        </div>
    )
}

export default GallerySection