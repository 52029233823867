import React from 'react'
import './Navbar.scss';
import { NavLink } from "react-router-dom";
import logo from '../../Images/logo.png'

const Navbar = ({ homeNav }) => {
    return (
        <div id='navbar'>
            <nav className={homeNav}>
                <div className="container-fluid navbarWidth">
                    <a href="/"><img className="navlogo" src={logo} alt="logo" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto pe-3 mt-1 mb-md-0">
                            <li className="nav-item ml-md-3">
                                <NavLink className="nav-link" to="/about">About</NavLink>
                            </li>
                            {/* <li className="nav-item ms-md-3">
                                <NavLink className="nav-link" to="/team/">Team</NavLink>
                             </li> */}
                            <li className="nav-item ms-md-3 dropdown">
                                <a className="nav-link dropdown-toggle" href="/team" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Team</a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/team">Founder</a></li>
                                    <li><a className="dropdown-item" href="/team#directors">Board of Directors</a></li>
                                    <li><a className="dropdown-item" href="/team#advisors">Board of Advisors</a></li>
                                    <li><a className="dropdown-item" href="/team#staff">Staff</a></li>
                                </ul>
                            </li>
                            <li className="nav-item ms-md-3">
                                <NavLink className="nav-link" to="/gallery">Gallery</NavLink>
                            </li>
                            <li className="nav-item ms-md-3">
                                <NavLink className="nav-link" to="/faq">FAQ</NavLink>
                            </li>
                        </ul>
                        <form className="d-flex">
                            <a href="/donate"><div className="btn donateButton text-nowrap">Donate</div></a>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar
