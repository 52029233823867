import React from 'react'
import './Donation.scss';
import donateImage from '../../Images/donateimage.png'

function Donation() {
    return (
        <div id='donation'>
            <div className='row donateContainer'>
                <div className='col-md flexCenter'>
                    <div className='donateText'>
                        <h1>Make a Donation</h1>
                        <div className='textIndent'>
                            <p>Your generous donations will enable us to meet our goals, service our clients, and make an impact.</p>
                        </div>
                        <a href="/donate/"><div className="btn donateButton text-nowrap">Donate Now</div></a>
                    </div>
                </div>
                <div className='col-md text-right rmpadding'>
                    <img src={donateImage} alt="Donate Image" className='donateImage' />
                </div>
            </div>
        </div>
    )
}

export default Donation