import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../Components/Navbar/Navbar';
import Landing from '../Components/Landing/Landing';
import About from '../Components/About/About';
import Donation from '../Components/Donation/Donation'
import Questions from '../Components/Questions/Questions'
import Testimonials from '../Components/Testimonials/Testimonials'
import Footer from '../Components/Footer/Footer'
import Newsletter from '../Components/Newsletter/Newsletter'

//Styles
import '../Components/Navbar/Navbar.scss'

// Images
import aboutImage from '../Images/abouthome.jpg'
import teamImage from '../Images/teamimage.jpeg'

function Home() {

  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  window.addEventListener('scroll', changeBackground);

  return (
    <div>
      <Navbar
        homeNav={navbar ? 'navbar navbar-expand-md navbar-dark bg-custom fixed-top navbarScroll' : 'navbar navbar-expand-md navbar-dark bg-custom fixed-top'}
      />
      <Landing />
      <About
        homeImage={aboutImage}
        aboutHeader="FAMIL is dedicated to ensuring that Afghan partners who served on behalf of the United States are supported as they resettle in America and strive to rebuild the lives they sacrificed in Afghanistan."
        p1="Our vision is for displaced Afghan families who served our country to rebuild their lives, pursue their dreams, and become contributing members of their new communities in the United States."
        p2="To fulfill this vision, FAMIL provides comprehensive support and customized solutions to assist these Afghans through services focused on Employment, Education, and Integration in America. FAMIL’s team includes high-skilled individuals with requisite language skills, deep cultural expertise, and extensive shared experiences with the Afghans we support."
        // p3="We provide comprehensive support to Afghan families, with a focus on Employment, Education, and Integration services."
        // p4="Our vision is for displaced Afghan families to rebuild their lives, pursue their dreams, and become contributing members of their new communities in the United States."
        link="/about/"
      />
      <Testimonials />
      <Donation />
      <About
        homeImage={teamImage}
        aboutHeader="Who are we?"
        p1="FAMIL is driven by a team of individuals with a diversity of experiences, a passion for results, and a commitment to service."
        p2="We are former US intelligence professionals, US military veterans, and Afghan-Americans who served with Afghan partners in Afghanistan."
        p3="We are first-and-second generation Americans with multicultural backgrounds, and Afghans on the path towards US citizenship."
        p4="We have a deep understanding of the needs of the Afghan community and a proficiency in implementing solutions to meet those needs."
        p5='We are diverse, we are committed, and we care.'
        link="/team/"
      />
      <Questions />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Home;
