import React from 'react'
import './Footer.scss'
import logo from '../../Images/logo.png'

function Footer() {
    return (
        <div id="footer">
            <div className='footerContainer'>
                <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-4 footerSpacing">
                    <div className="col-md col-xl-3 mb-2">
                        <img className="footerLogo" src={logo} alt="logo" />
                        {/* <h3 className="rights">All rights reserved.</h3> */}
                        <h3 className="rights">FAMIL is a 501(c)(3) tax exempt, national nonprofit organization.</h3>
                    </div>
                    <div className="col-md mb-4 maxWidth">
                        <h3 className="copyright">About</h3>
                        <li>
                            <a className="footer-link" href="/about/">About Us</a>
                        </li>
                        <li>
                            <a className="footer-link" href="/team/">Meet Our Team</a>
                        </li>
                        <li>
                            <a className="footer-link" href="/donate/">Donate Today</a>
                        </li>
                    </div>
                    <div className="col-md mb-4 maxWidth">
                        <h3 className="copyright">Media</h3>
                        <li>
                            <a className="footer-link" href="https://twitter.com/FAMIL_USA" target="_blank">Twitter</a>
                        </li>
                        <li>
                            <a className="footer-link" href="https://www.instagram.com/famil.usa/" target="_blank">Instagram</a>
                        </li>
                        <li>
                            <a className="footer-link" href="https://www.facebook.com/familusa.org" target="_blank">Facebook</a>
                        </li>
                    </div>
                    <div className="col-md maxWidth">
                        <h3 className="copyright">Contact</h3>
                        <li>
                            <a className="footer-link" href="mailto:familorg@gmail.com">support@familusa.org</a>
                        </li>
                        <li>
                            <span className="footer-link">(571) 440-7779</span>
                        </li>
                        <li>
                            <span className="footer-link">PO Box 9263, McLean, VA 22102</span>
                        </li>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer