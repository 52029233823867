import React from 'react'
import './Faq.scss'
import Accordion from 'react-bootstrap/Accordion'

// Images
import aboutImage from '../../Images/faq.png'

function Faq() {
    return (
        <div id='faq'>
            <div className='row'>
                <div className='col-md text-center order-2 order-md-1'>
                    <img src={aboutImage} alt="Image" className='aboutImage2' />
                </div>
                <div className='col-md order-1 order-md-2'>
                    <div className='faqContainer'>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Who are the Afghan partners that FAMIL serves?</Accordion.Header>
                                <Accordion.Body>
                                    Our Afghan partners include Afghan special forces and other individuals who served on behalf of the US Government for two decades. These brave men and women supported the US Government’s counterterrorism activities in some of the most dangerous parts of Afghanistan, risking their lives to protect American lives. Our Afghan partners made tremendous sacrifices on the battlefield – they suffered disproportionate casualties in comparison to US troops. They were key to creating a security umbrella that allowed the United States to operate throughout Afghanistan – safely and effectively – for 20 years. Now in the United States, our Afghan partners are looking to FAMIL to be their lifeline in the country they served for so long.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Among all the disenfranchised groups I could support, why this cause?</Accordion.Header>
                                <Accordion.Body>
                                    Certainly, FAMIL believes in supporting disadvantaged Americans, as well as new immigrants to the United States. Our Afghan partners are particularly deserving of your support for two reasons: 1) They fought for two countries – the United States and Afghanistan – which they lost through no fault of their own. It was a decision that came out of necessity following the withdrawal of US troops from Afghanistan in August 2021. 2) They are Afghan combat veterans of the US war in Afghanistan. They lived, worked, and breathed alongside US service members – putting their own lives at risk to protect American lives. We have a moral obligation to give them back what they lost – to help them succeed in their new homeland.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>I thought the US Government and national resettlement agencies are responsible for managing resettlement. Why does FAMIL need to be involved?</Accordion.Header>
                                <Accordion.Body>
                                    The US Government and national resettlement agencies have a number of challenges in meeting the needs of this population. One, they are overwhelmed and understaffed, leaving them unable to handle the size and complexity of an issue of this magnitude. Two, they are faced with competing priorities due to other domestic requirements and international events. Three, they lack the entire mission-set that FAMIL brings to bear in a single organization – Employment, Education, and Integration into America. FAMIL maintains years of experience in working with our Afghan partners – both in Afghanistan and the United States – and has the expertise to address their immediate and unmet needs in an effective, timely manner.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>How do you intend to spend the money you raise?</Accordion.Header>
                                <Accordion.Body>
                                    FAMIL intends to maximize funds for service delivery with minimal overhead costs. We are lean, agile, and data-driven. We continue to develop and refine our programs to ensure the best available services for our clients, while incorporating what we learn to build upon our success as we grow. We are committed to ensuring that funds will be spent on the highest-impact, highest-return goals.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq